<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import { ProcessLog } from '@/found/modules';
import Form from './form';

export default {
  name: 'new_product_apply_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        code: '',
      },
    };
  },
  components: {
    Modal,
    Form,
    ProcessLog,
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      const { engbleStatus, processCode } = row;
      if (engbleStatus && code === 'edit' && (engbleStatus === '2' || engbleStatus === '3')) { // 审批中或者通过不能编辑
        return false;
      }
      if (code === 'process_log' && !processCode) {
        return false;
      }
      return true;
    },
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig = {
          code: val.code,
          id: null,
        };
        this.openFull();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          code: val.code,
          row,
        };
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          ...val,
          code: val.code,
          row,
        };
        this.openFull();
      } else if (val.code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
    },
  },
  created() {
    this.getConfigList('new_product_apply_list');
  },
};
</script>
